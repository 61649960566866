var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "error-page" } }, [
    _c("img", {
      attrs: { src: require("@assets/img/logo-dplot.svg"), alt: "D.PLOT" },
    }),
    _c("h2", [_vm._v("서비스 이용에 불편을 드려 죄송합니다.")]),
    _vm._m(0),
    _c("div", { staticClass: "btn-wrap" }, [
      _c(
        "button",
        {
          staticClass: "prev",
          attrs: { type: "button" },
          on: { click: _vm.backPage },
        },
        [_vm._v("이전 페이지")]
      ),
      _c(
        "button",
        {
          staticClass: "main",
          attrs: { type: "button" },
          on: { click: _vm.main },
        },
        [_vm._v("메인 페이지")]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v(
        " 입력하신 페이지의 주소가 잘못 입력되었거나, 변경 혹은 삭제되어 요청하신 페이지를 찾을 수 없습니다."
      ),
      _c("br"),
      _vm._v(" 입력하신 주소가 정확한지 다시 한번 확인해주세요."),
      _c("br"),
      _vm._v(" 서버에서 정보를 가져오는 도중 오류가 발생하였습니다."),
      _c("br"),
      _vm._v(" 잠시 후 다시 시도해주세요. "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }